import React from 'react'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import './Plans.css'

const Plans = () => {
  return (
    <div className="plan-container">
        <div className="blur plan-blur-1"></div>
        <div className="blur plan-blur-2"></div>

        <div className="program-header">
            <span className='text-vide'>READY TO START</span>
            <span>YOR JOURNEY</span>
            <span className='text-vide'>NOW WITH US</span>
        </div>
        {/*Plans Cards*/}
        <div className="plans">
            {plansData.map((plan,i)=>(
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>${plan.price}</span>
                    <div className="features">
                        {plan.features.map((feature,i)=>
                        <div className="feature">
                            <img src={whiteTick} alt="" />
                            <span key={i}>{feature}</span>
                        </div>

                        )}
                    </div>
                    <div>
                <span>See more benifits -></span>
            
            </div>
            <button className="btn">Join now</button>
                </div>
                
            ))}
            

        </div>
    </div>
  )
}

export default Plans