import React from 'react'
import './Program.css';
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Program = () => {
  return (
    <div className="program" id='program'>
  
<div className="program-header">
    <span className='text-vide'>Explore out</span>
    <span>Programs</span>
    <span className='text-vide' >to shape you</span>
  </div>
  <div className="programs-categories">
    {
        programsData.map((program)=>
        <div className="category">
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now"><span>Join NOW</span><img src={RightArrow} alt="" /></div>

        </div>

        )
    }
  </div>
  </div>
  )
}

export default Program